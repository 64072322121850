import React from 'react';
import ReactEcharts from 'echarts-for-react';

const lifeStages = [
  { year: 0, description: 'Birth', icon: '👶' },
  { year: 5, description: 'Start School', icon: '🧒' },
  { year: 18, description: 'Graduate High School', icon: '👨‍🎓' },
  { year: 22, description: 'Graduate College', icon: '🎓' },
  { year: 23, description: 'Start Career', icon: '👨‍💼' },
  { year: 30, description: 'Marriage', icon: '💍' },
  { year: 65, description: 'Retirement', icon: '🧓' },
  { year: 100, description: '100th Birthday', icon: '🎉' }
];

const HumanLifeChart = () => {
  const option = {
    title: {
      text: 'Human Life Journey Over 100 Years'
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        const year = params[0].axisValue;
        const event = lifeStages.find(item => item.year === parseInt(year));
        return event ? `${year}: ${event.description}` : `${year}: No major event`;
      }
    },
    xAxis: {
      type: 'category',
      data: Array.from({ length: 101 }, (_, i) => i) // Years from 0 to 100
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 100
    },
    series: [
      {
        name: 'Milestones',
        type: 'line',
        data: lifeStages.map(item => [item.year, item.year]) // The value is the same as the year for simplicity
      }
    ],
    graphic: lifeStages.map(stage => ({
      type: 'text',
      left: `${stage.year}%`,
      top: '10%',
      style: {
        text: stage.icon,
        fontSize: 16,
      }
    }))
  };

  return <ReactEcharts option={option} />;
};

export default HumanLifeChart;
